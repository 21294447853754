<template>
  <div
    :class="cartStore.isLoading ? 'opacity-50' : null"
    class="relative flex space-x-5 transition-opacity duration-150"
  >
    <ShopifyImage
      v-if="props.cartLine.merchandise.image"
      :image="props.cartLine.merchandise.image"
      class="cloud-mask-lg w-40 flex-none"
    />
    <div class="flex min-w-0 flex-grow flex-col items-start justify-center">
      <div class="w-full space-y-4">
        <div class="type-body-sm flex w-full flex-col space-y-1">
          <div class="flex justify-between">
            <h3
              v-text="
                `${props.cartLine.merchandise.product.title}${
                  isSubscription ? ' Subscription' : ''
                }`
              "
            />
            <span
              v-text="
                $helpers.formatPrice(props.cartLine.cost.totalAmount.amount)
              "
            />
          </div>
          <span v-if="isVariant" class="text-black/50">
            <span v-if="isSubscription && quantity > 1"
              >{{ quantity }} x&nbsp;</span
            >
            <span v-text="props.cartLine.merchandise.title" />
          </span>
          <span
            v-if="isSubscription"
            class="text-black/50"
            v-text="subscriptionText"
          />
        </div>
        <InputQuantity
          v-model="quantity"
          :disabled="isSampler"
          class="mb-4 h-10 w-28 space-x-2 border border-cream-dark bg-cream-light p-2 md:h-12 md:w-36"
          button-class="h-6 w-6 bg-cream-dark text-black hover:bg-purple disabled:text-black/20 disabled:hover:bg-cream-dark md:h-8 md:w-8"
        />
      </div>
      <BaseButton
        :class="isSubscription ? '' : 'mb-2'"
        :disabled="cartStore.isLoading"
        class="mt-auto text-orange"
        @click="cartStore.removeCartLines(props.cartLine.id)"
      >
        Remove
      </BaseButton>
    </div>
    <div v-if="cartStore.isLoading" class="absolute inset-0 cursor-wait" />
  </div>
</template>

<script lang="ts" setup>
import type { CartLineFragment } from '@/shopify-storefront'

interface CartLineItemProps {
  cartLine: CartLineFragment
}

const props = defineProps<CartLineItemProps>()
const isSubscription = computed(() => !!props.cartLine.sellingPlanAllocation)
const isVariant = computed(
  () => props.cartLine.merchandise.title !== 'Default Title',
)

const { hasNewSellingPlanStructure, isSampler } = useShopifyProduct(
  props.cartLine.merchandise.product,
)
const subscriptionText = computed(() =>
  hasNewSellingPlanStructure.value
    ? props.cartLine.sellingPlanAllocation?.sellingPlan.name
    : `Delivered every ${props.cartLine.sellingPlanAllocation?.sellingPlan.options[0].value}`,
)

const cartStore = useCartStore()

const QUANTITY_DEBOUNCE_DURATION = 500
const quantity = ref(props.cartLine.quantity)
const debouncedQuantity = refDebounced(quantity, QUANTITY_DEBOUNCE_DURATION)

watch(debouncedQuantity, () => {
  cartStore.updateCartLines([
    {
      id: props.cartLine.id,
      quantity: debouncedQuantity.value,
    },
  ])
})
</script>
