<template>
  <button type="button" @click="toggleNavMenu">
    <svg
      class="stroke-current"
      width="26"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1h24" stroke-width="2" stroke-linecap="round" />
      <path d="M1 9h24" stroke-width="2" stroke-linecap="round" />
      <path d="M1 17h24" stroke-width="2" stroke-linecap="round" />
    </svg>
  </button>
</template>

<script lang="ts" setup>
const { toggleNavMenu } = useUIStore()
</script>
