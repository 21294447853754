<template>
  <div
    :class="isAdding ? 'cursor-wait' : ''"
    class="flex items-center space-x-4 rounded-md bg-cream-dark p-3"
  >
    <div class="cloud-mask-lg h-16 w-16 flex-none">
      <ShopifyImage
        v-if="image"
        :image="image"
        class="h-full w-full object-cover"
      />
    </div>
    <div class="type-body-sm flex flex-grow flex-col">
      <h3 class="text-base" v-text="props.product.title" />
      <span
        class="text-black/50"
        v-text="
          $helpers.formatPrice(props.product.priceRange.minVariantPrice.amount)
        "
      />
    </div>
    <BaseAsyncButton
      :busy="isAdding"
      class="btn-cream flex h-10 w-10 items-center justify-center rounded-full hover:bg-opacity-75"
      spinner-class="text-orange"
      @click="onAdd"
    >
      <SvgGlyphPlus />
    </BaseAsyncButton>
  </div>
</template>

<script lang="ts" setup>
import type { ProductFragment } from '@/shopify-storefront'

interface CartAddonProps {
  product: ProductFragment
}

const props = defineProps<CartAddonProps>()
const image = computed(() => props.product.images.nodes[0])

const emit = defineEmits(['add'])
const isAdding = ref(false)

function onAdd() {
  isAdding.value = true
  emit('add')
}
</script>
