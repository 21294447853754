function getRealTotalLength(el: SVGPathElement) {
  let ratio = 1

  const bbox = el.getBBox()
  const rect = el.getBoundingClientRect()

  if (rect.width) {
    ratio = rect.width / bbox.width
  } else if (rect.height) {
    ratio = rect.height / bbox.height
  }

  return el.getTotalLength ? el.getTotalLength() * ratio : 0
}

export const setDashOffset = (el: SVGPathElement) => {
  let pathLength = getRealTotalLength(el)
  pathLength = Math.round(pathLength * 1000) / 1000
  el.setAttribute('stroke-dasharray', pathLength.toString())
  return pathLength
}
