<template>
  <div class="fixed inset-0 bottom-auto z-30 select-none">
    <PageBanner class="relative z-[3]" />
    <div>
      <div
        :class="
          !showShopTray && isHomePage && !hasScrolled
            ? 'border-transparent bg-transparent text-white hover:border-cream-dark hover:bg-cream-light hover:text-black'
            : 'border-cream-dark bg-cream-light text-black'
        "
        class="nav-bar-height relative z-[3] w-full overflow-hidden border-b bg-cream-light transition-colors duration-100"
      >
        <NuxtLoadingIndicator :color="theme!.colors!.orange!" />
        <div class="gutters flex h-full items-center">
          <div class="hidden items-center space-x-8 lg:flex lg:flex-1">
            <div class="relative">
              <NavBarLink to="/collections">Shop</NavBarLink>
              <button
                class="absolute inset-0 h-full w-full"
                type="button"
                @click="toggleShopTray"
              />
            </div>
            <NavBarLink to="/mission" @click="hideShopTray">Mission</NavBarLink>
            <NavBarLink to="/faqs" @click="hideShopTray">FAQs</NavBarLink>
          </div>
          <NavBarMenuButton class="lg:hidden" />
          <div class="flex flex-1 justify-center">
            <NuxtLink to="/" @click="hideShopTray">
              <SvgNimbusLogotype class="h-8 w-auto md:h-10" />
            </NuxtLink>
          </div>
          <div class="flex items-center justify-end lg:flex-1 lg:space-x-8">
            <NavBarLink
              :to="accountStore.customer ? '/account' : '/log-in'"
              class="hidden lg:inline-block"
              @click="hideShopTray"
            >
              {{ accountStore.customer ? 'Account' : 'Log In' }}
            </NavBarLink>
            <CartButton class="btn-orange" />
          </div>
        </div>
      </div>
      <div
        :class="
          showShopTray
            ? 'lg:translate-y-full'
            : 'translate-y-[-200%] lg:translate-y-0'
        "
        class="absolute inset-x-0 bottom-0 z-[2] transition-transform duration-500 ease-slide"
      >
        <NavBarShopTray @dismiss="hideShopTray" />
        <div class="nav-bar-height" />
      </div>
      <BaseShade
        :show="showShopTray"
        class="absolute inset-0 bottom-auto h-screen cursor-s-resize"
        @click="hideShopTray"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { theme } from '#tailwind-config'

const accountStore = useAccountStore()
const uiStore = useUIStore()
const showShopTray = ref(false)

function hideShopTray() {
  showShopTray.value = false
}

function toggleShopTray() {
  showShopTray.value = !showShopTray.value
}

const route = useRoute()
const isHomePage = computed(() => route.path === '/')
const hasScrolled = ref(false)

// const showBorder = computed(() => showShopTray.value)

onMounted(() => {
  const { y: scrollTop } = useScroll(window)

  watchEffect(() => {
    if (isHomePage.value) {
      hasScrolled.value = scrollTop.value > 0 || uiStore.scrollLock.enabled
    } else {
      hasScrolled.value = true
    }
  })
})
</script>

<style lang="postcss" scoped>
:deep(.nuxt-loading-indicator) {
  position: absolute !important;
}
</style>
