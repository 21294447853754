<template>
  <BaseButton
    class="cloud-mask-sm md:group h-8 w-8 overflow-hidden rounded-none md:h-10 md:w-10"
    @click="cartStore.showCart = true"
  >
    <span
      v-for="n in 2"
      :key="n"
      :class="
        n === 1
          ? 'group-hover:-translate-y-full'
          : 'translate-y-full group-hover:translate-y-0'
      "
      class="absolute inset-0 flex items-center justify-center text-sm font-medium group-hover:transition-transform group-hover:duration-300"
      v-text="cartLines.length"
    />
  </BaseButton>
</template>

<script lang="ts" setup>
const cartStore = useCartStore()
const cart = useCart()

const cartLines = computed(() => cart.value?.lines.nodes ?? [])
</script>
