<template>
  <div class="relative inline-block text-lg">
    <NuxtLink
      :to="props.to"
      :class="isActive ? 'text-orange' : ''"
      class="cursor-pointer transition-colors duration-100"
    >
      <slot />
    </NuxtLink>
    <div
      :class="isReady ? null : 'opacity-0'"
      class="pointer-events-none absolute -inset-x-[0.1em] -bottom-[0.2em]"
    >
      <svg
        class="w-full stroke-orange"
        viewBox="0 0 45 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          ref="underline"
          d="M1 4.3C6.265 2.378 14.976 1 28 1c4 0 11 0 16 1.5"
          stroke-width="2"
          stroke-linecap="round"
          vector-effect="non-scaling-stroke"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts" setup>
interface NavBarLinkProps {
  to: string
}

const props = defineProps<NavBarLinkProps>()

const { $anime } = useNuxtApp()
const route = useRoute()
const underline = ref<SVGPathElement | null>(null)
const isActive = computed(
  () => route.fullPath !== '/' && route.fullPath.includes(props.to),
)
const isReady = ref(false)
const isAnimating = ref(false)

let requestId: number

function setStrokeDashoffset() {
  if (!isAnimating.value) {
    const pathLength = setDashOffset(underline.value!)
    $anime.set(underline.value, {
      strokeDashoffset: isActive.value ? 0 : pathLength * -1,
    })
  }

  requestId = requestAnimationFrame(setStrokeDashoffset)
}

function showPath() {
  $anime({
    targets: underline.value,
    strokeDashoffset: [setDashOffset(underline.value!), 0],
    easing: 'easeInOutSine',
    duration: 150,
    begin() {
      isAnimating.value = true
    },
    complete() {
      isAnimating.value = false
      setStrokeDashoffset()
    },
  })
}

function hidePath() {
  $anime({
    targets: underline.value,
    strokeDashoffset: [0, setDashOffset(underline.value!) * -1],
    easing: 'easeInOutSine',
    duration: 150,
    begin() {
      isAnimating.value = true
    },
    complete() {
      isAnimating.value = false
      setStrokeDashoffset()
    },
  })
}

onMounted(() => {
  setStrokeDashoffset()

  if (isActive.value) {
    showPath()
  }

  isReady.value = true

  watch(isActive, (newIsActive) => {
    cancelAnimationFrame(requestId)

    if (newIsActive) {
      showPath()
    } else {
      hidePath()
    }
  })
})

onBeforeUnmount(() => {
  cancelAnimationFrame(requestId)
})
</script>
