<template>
  <div :class="uiStore.scrollLock.enabled ? 'h-screen overflow-hidden' : ''">
    <div
      :class="uiStore.scrollLock.enabled ? 'relative' : ''"
      :style="`top: -${uiStore.scrollLock.scrollTop}px`"
    >
      <PageBanner class="opacity-0" />
      <div class="overflow-hidden">
        <slot />
      </div>
      <NavBar />
      <NavBarMenu />
      <CartTray />
    </div>
  </div>
</template>

<script lang="ts" setup>
const globalsStore = useGlobalsStore()
const uiStore = useUIStore()

if (globalsStore.site?.banner.isEnabled) {
  setTimeout(() => {
    uiStore.showBanner = true
  }, 3000)
}
</script>
